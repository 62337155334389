<template>
  <div class="caseM70-wrap">
    <div class="caseM70head">
      <HeaderA></HeaderA>
    </div>
    <div class="caseM70content">
      <router-view/>
    </div>
  </div>

</template>


<script>
// @ is an alias to /src
import HeaderA from '@/components/layout/headerA.vue'

export default {
  name: 'Home',
  data() {
    return {
      nowHome:''
    }
  },
  components: {
    HeaderA
  },
  created() {
    this.nowHome = this.$route.query.nowHome;

  }
}
</script>
<style lang="scss" scoped>
//.caseMain-wrap {
//  height: 100%;
//  box-sizing: border-box;
//  .caseMain-content{
//    width: 100%;
//    //calc用上就不用margin、padding了
//    height: calc(100% - 70px);
//    background-color: red;
//    overflow-y: auto;
//  }
//}
.caseM70-wrap{
  width: 100%;
  display: flex;
  background-size: 100% 100%;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  overflow-y: hidden;
  .caseM70head{
    width: 100%;
    height: 70px;
    position: fixed;
    z-index: 0;
  }
  .caseM70content{
    width: 100%;
    position: fixed;
    /*固定定位*/
    top: 70px;
    bottom: 0;
    overflow-y: auto;
  }
}
.caseM110-wrap{
  width: 100%;
  display: flex;
  background: url("../assets/images/main-wrap.png") center center no-repeat;
  background-size: 100% 100%;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  overflow-y: hidden;
  .caseM110head{
    width: 100%;
    height: 110px;
    position: fixed;
    z-index: 0;
  }
  .caseM110content{
    width: 100%;
    position: fixed;
    /*固定定位*/
    top: 110px;
    overflow-y: auto;
  }
}


</style>
