<template>
  <span>
      <!--这是学生-->
      <div class="layoutHeader-1" v-if="nowHome==1&&stu==1">
         <div class="logo-header">
            <div class="content">
              <div class="head-login"><img src="../../assets/images/head-logo.png" alt=""></div>
              <div class="head-infor">
                <div class="left">
                  <div class="ha01"> 余额：{{StuByUserData.account}}学币</div>
                  <div class="ha02" @click="toRecharge()">充值</div>
                  <div class="ha03" @click="toOrder()">订单</div>
                  <div class="ha04">
                    <el-button size="mini" round class="case_i" @click="toCart()"><div class="car">{{cartCount}}</div>购物车</el-button>
                  </div>
                </div>
                <div class="right">
                  <div class="ha04">
<!--                    <div><i class="el-icon-message"></i></div> 消息-->
                  </div>
                  <div class="ha05">
                    <div><i class="el-icon-user-solid"></i></div>
                    <el-dropdown>
                      <span class="el-dropdown-link">
                        <div class="drop-1">{{StuByUserData.stuName}}</div>
                        <div class="drop-2">
                          <i class="el-icon-arrow-down"></i>
                        </div>
                      </span>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <div class="dcr-menu"><el-button type="text" @click="toRevise()">个人资料</el-button></div>
                          <div class="dcr-menu"><el-button type="text"
                                                           @click="toStuChangePassword()">修改密码</el-button></div>
                          <div class="dcr-menu"><el-button type="text" @click="outLogin()">退出</el-button></div>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <!--这是家长-->
      <div class="layoutHeader-1" v-if="nowHome==1&&par==1">
          <div class="logo-header">
            <div class="content">
              <div class="head-login"><img src="../../assets/images/head-logo.png" alt=""></div>
              <div class="head-infor">
                <div class="left">

                </div>
                <div class="right">
                  <div class="ha04">
<!--                    <div><i class="el-icon-message"></i></div> 消息-->
                  </div>
                  <div class="ha05">
                    <div><i class="el-icon-user-solid"></i></div>
                    <el-dropdown>
                      <span class="el-dropdown-link">
                        <div class="drop-1">{{ParFamilyInfo.familyName}}</div>
                        <div class="drop-2">
                          <i class="el-icon-arrow-down"></i>
                        </div>
                      </span>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <div class="dcr-menu"><el-button type="text" @click="toParregister()">个人资料</el-button></div>
                          <div class="dcr-menu"><el-button type="text"
                                                           @click="toParChangePassword()">修改密码</el-button></div>
                          <div class="dcr-menu"><el-button type="text" @click="outLogin()">退出</el-button></div>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>

      </div>

  </span>


</template>


<script>
    import {mapState} from 'vuex'

    export default {
        name: '',
        data() {
            return {
                nowHome: 0,
                par: '',
                userInfo: {},
                portraitList: [
                    {id: 1, url: require('../../assets/images/m1.png')},
                    {id: 2, url: require('../../assets/images/m2.png')},
                ],
                picImgSrc: {}

            }
        },
        computed: {
            ...mapState([
                'cartCount',
                'StuByUserData',
                'ParFamilyInfo',
                'ParChildren'
            ])
        },
        created() {
            this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
            this.nowHome = this.$route.query.nowHome;
            this.EducationCase = this.$route.query.EducationCase;
            this.clickFlag = this.$route.query.clickFlag
            this.par = this.$route.query.par
            this.stu = this.$route.query.stu
            this.portraitList.forEach((ele) => {
                if (1 == ele.id) {
                    this.picImgSrc = ele
                }
            })
        },
        watch: {
            // 监视搜索词变化
            $route() {
                this.clickFlag = this.$route.query.clickFlag
            },

        },
        methods: {
            outLogin() {
                // 清除学生端 缓存
                this.ClearStuAll()
                // 清除学生端 缓存
                this.ClearParentAll()
                this.$confirm('您将退出您的账号, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    localStorage.removeItem("userInfo");
                    localStorage.removeItem("status");
                    localStorage.removeItem("Authorization");
                    this.$router.replace({
                        path: "/",
                        meta: {allowBack: false}
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
                // MessageBox.$confirm("您将退出您的账号, 是否继续?", "提示", {
                //   confirmButtonText: "确定",
                //   cancelButtonText: "取消",
                //   type: "warning"
                // })
                //  .then(() => {
                //       localStorage.removeItem("userInfo");
                //       localStorage.removeItem("status");
                //       localStorage.removeItem("Authorization");
                //       this.$router.replace({
                //         path: "/",
                //         meta: {allowBack: false}
                //       });
                //     })
                //     .catch(() => {
                //
                //  });
            },
            // 跳转到充值
            toRecharge() {
                // 清除学生端 缓存
                this.ClearStuAll()
                // 清除学生端 缓存
                this.ClearParentAll()
                this.$router.push({
                    path: "/mainb/recharge",
                    query: {
                        EducationCase: 1,
                        clickFlag: 0,
                        orderCur: 0,
                        stu: 1
                    }
                });
            },
            // 跳转到订单
            toOrder() {
                // 清除学生端 缓存
                this.ClearStuAll()
                // 清除学生端 缓存
                this.ClearParentAll()
                this.$router.push({
                    path: "/mainb/order",
                    query: {
                        EducationCase: 1,
                        clickFlag: 0,
                        orderCur: 0,
                        stu: 1
                    }
                });
            },
            // 跳转到购物车
            toCart() {
                // 清除学生端 缓存
                this.ClearStuAll()
                // 清除学生端 缓存
                this.ClearParentAll()
                this.$router.push({
                    path: "/mainb/Cart",
                    query: {
                        EducationCase: 1,
                        clickFlag: 0,
                        acurA: 1,
                        stu: 1
                    }
                });
            },
            // 跳转到学生注册
            toStuRegister() {
                // 清除学生端 缓存
                this.ClearStuAll()
                // 清除学生端 缓存
                this.ClearParentAll()
                this.$router.push({
                    path: "/register",
                    query: {
                        noreg: 1
                    }
                });
            },
            // 跳转到家长个人信息
            toParregister() {
                // 清除学生端 缓存
                this.ClearStuAll()
                // 清除学生端 缓存
                this.ClearParentAll()
                this.$router.push({
                    path: "/ParRevise",
                    query: {
                        EducationCase: 2,
                        par: 1
                    }
                });
            },
            // 跳转到学生密码修改
            toParChangePassword() {
                // 清除学生端 缓存
                this.ClearStuAll()
                // 清除学生端 缓存
                this.ClearParentAll()
                this.$router.push({
                    path: "/parChangePassword",
                    query: {
                        EducationCase: 2,
                        par: 1
                    }
                });
            },
            // 跳转到个人信息
            toRevise() {
                // 清除学生端 缓存
                this.ClearStuAll()
                // 清除学生端 缓存
                this.ClearParentAll()
                this.$router.push({
                    path: "/revise",
                    query: {
                        EducationCase: 1,
                        stu: 1
                    }
                });
            },

            // 跳转到学生密码修改
            toStuChangePassword() {
                // 清除学生端 缓存
                this.ClearStuAll()
                // 清除学生端 缓存
                this.ClearParentAll()
                this.$router.push({
                    path: "/stuChangePassword",
                    query: {
                        EducationCase: 1,
                        stu: 1
                    }
                });
            },
            // 清除学生端 缓存
            ClearStuAll() {
                this.ClearDetailsAll()
            },
            // 清除学生端 缓存
            ClearParentAll() {

            },
            // 学生端-学历案课件 清除缓存
            ClearDetailsAll() {
                // 交互答疑 主题列表点击事件 清除缓存
                sessionStorage.removeItem("curChange");
                sessionStorage.removeItem("topicIdChange");
                sessionStorage.removeItem("TopicReplyListChange");
            }


        },
        components: {}
    }
</script>
<style lang="scss" scoped>
    .layoutHeader-1 {
        .logo-header {
            height: 70px;
            width: 100%;
            background: url("../../assets/images/header.png") no-repeat;
            background-size: 100% 70px;
            position: relative;

            .content {
                width: 98%;
                margin: auto;
                display: flex;
                //logo
                .head-login {
                    width: 40%;
                    height: 70px;
                    justify-content: flex-start;
                    cursor: pointer;

                    img {
                        height: 54px;
                        margin-top: 8px;
                    }
                }

                //flex两列布局
                .head-infor {
                    width: 60%;
                    justify-content: center;
                    margin-top: 24px;
                    display: flex;

                    .left {
                        width: 50%;
                        display: flex;

                        .ha01 {
                            height: 30px;
                            line-height: 30px;
                            padding: 0 50px 0 30px;
                            box-sizing: border-box;
                            border-right: 1px solid #bbb;
                            background: url("../../assets/images/Case-09.png") no-repeat 0px center;
                            background-size: 26px;
                        }

                        .ha02 {
                            height: 30px;
                            line-height: 30px;
                            padding: 0 10px;
                            cursor: pointer;
                            color: #0060F1;
                            border-right: 1px solid #bbb;
                        }

                        .ha03 {
                            height: 30px;
                            line-height: 30px;
                            cursor: pointer;
                            padding: 0 10px;
                            color: #0060F1;
                        }

                        .ha04 {
                            width: 90px;
                            height: 24px;
                            line-height: 24px;
                            cursor: pointer;
                            padding: 0 10px;
                            color: #0060F1;

                            .case_i {
                                background: url("../../assets/images/case_icon03.png") no-repeat 10px center;
                                background-size: 22px 19px;
                                padding-left: 40px;
                                margin-right: 10px;
                                border: 1px solid #FF9100;
                                background-color: #fff;
                                color: #FF9100;
                                position: relative;

                                .car {
                                    width: 20px;
                                    height: 20px;
                                    line-height: 20px;
                                    border-radius: 50px;
                                    background-color: #FF9100;
                                    color: #fff;
                                    position: absolute;
                                    top: -16px;
                                    left: 30px;
                                }

                            }
                        }
                    }

                    .right {
                        width: 50%;
                        flex-grow: 0;
                        display: flex;
                        justify-content: flex-end;

                        .ha04 {
                            height: 30px;
                            line-height: 30px;
                            display: flex;
                            justify-content: center;

                            i {
                                margin-top: 8px;
                                margin-right: 6px
                            }
                        }

                        .ha05 {
                            height: 30px;
                            line-height: 20px;
                            padding: 0 10px;
                            color: #0060F1;
                            display: flex;

                            div {
                                margin-top: 6px;
                                margin-left: 5px;
                                margin-right: 2px
                            }

                            .el-dropdown-link {
                                display: flex;

                                .drop-1 {
                                    height: 20px;
                                    margin-top: 0px;
                                }

                                .drop-2 {
                                    margin-top: 0px;
                                }
                            }
                        }

                        .ha08 {
                            padding: 0 10px;
                            color: #0060F1;
                            display: flex;

                            div {
                                margin-top: 3px;
                                margin-left: 5px;
                                margin-right: 2px
                            }

                            .el-dropdown-link {
                                display: flex;

                                .drop-1 {

                                }

                                .drop-2 {
                                    margin-top: 0px;
                                }
                            }
                        }
                    }

                }
            }
        }

        .nav-header {
            width: 100%;
            height: 40px;
            background-color: #0090DA;

            .content {
                width: 98%;
                margin: auto;
            }
        }
    }

    .dcr-menu {
        width: 120px;
        box-sizing: border-box;
        padding-left: 10px;
    }

    .dcr-menu:hover {
        background-color: #EBF5FB;
    }

</style>
